import {VierdaagseResult} from "../types/VierdaagseApi";
import {matchArtists} from "./matchArtists";
import {locationIdToLocation} from "./helper";

export async function getVierdaagseAPI(){
    return fetch('/vierdaagse').then((response) => {
        return response.json().then((data: VierdaagseResult) => {
            const newPrograms = []
            for(let act of data.programs) {
                if(!act.location){
                    act.location = {
                            id: 0,
                            title: "Unknown",
                            description_short: 'Unknown',
                            description: "Unknown",
                            marker: {
                                lat: 0,
                                lng: 0
                            },
                            logo: "",
                            images: [],
                            url: "",
                            slug: "",
                            type: ""
                    }
                }else{
                    act.location = locationIdToLocation(act.location.id, data.locations);
                }
                newPrograms.push(act);
            }
            data.programs = newPrograms;
            return data;
        }).catch((error) => {
            console.log(error);
            return null;
        });
    }).catch((error) => {
        console.log(error);
        return null;
    });
}
