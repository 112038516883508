import {Program} from "../types/VierdaagseApi";
import {Artist} from "../types/artist";
import {SavedArtists} from "../types/song";
import {getVierdaagseAPI} from "./vierdaagse";
import SpotifyConnection from "./spotify";

export async function matchArtists(program: Program[], artists: Artist[], spotify: SpotifyConnection) {
    let matches: Program[] = [];
    for (let act of program) {
        for (let artist of artists) {
            if (act.title.toLowerCase() === artist.name.toLowerCase()) {

                if (artist.picture === "") {
                    const artistData = await spotify.getArtistsPictureAndPlaylist(artist.id);
                    if(act.images.length > 0){
                        act.images[0].url = artistData.picture;
                    }
                    act.url = artistData.uri;
                }

                matches.push(act);
                break;
            }
        }
    }

    matches.sort((a, b) => {
        const aDate = new Date(a.day.date.slice(0, 10) + "T" + a.start_time);
        const bDate = new Date(b.day.date.slice(0, 10) + "T" + b.start_time);

        if (aDate < bDate) {
            return -1;
        } else {
            return 1;
        }
    });

    return matches;
}

export function findMatches(spotify: SpotifyConnection | null, setActs: (acts: Program[]) => void, setLoading: (loading: boolean) => void) {
    if(!spotify) return;
    if(!spotify.user) return;

    let previousArtistsItem = localStorage.getItem("artists");
    let previousArtists: SavedArtists | undefined = undefined;
    if(previousArtistsItem) {
        previousArtists = JSON.parse(previousArtistsItem);
    }
    setLoading(true);
    spotify.getMySavedSongs(previousArtists).then((artists) => {
        if(artists) {
            localStorage.setItem("artists", JSON.stringify(artists));
            getVierdaagseAPI().then(async (vierdaagse) => {
                if (vierdaagse) {
                    setActs(await matchArtists(vierdaagse.programs, artists.artists, spotify));
                    setLoading(false);
                }
            })
        }
    });
}
