import SpotifyWebApi from 'spotify-web-api-node';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SpotifyWebApiServer from 'spotify-web-api-node/src/server-methods';
import {SavedArtists, song} from "../types/song";
import {Artist} from "../types/artist";

(SpotifyWebApi as unknown as { _addMethods: (fncs: unknown) => void })._addMethods(
    SpotifyWebApiServer
);

class SpotifyConnection {
    spotifyApi: SpotifyWebApi | undefined;
    scopes: string[]= ['user-read-private', 'user-top-read', 'user-library-read'];
    redirectUri: string = "";
    clientId: string = "";
    state: string = Math.random().toString(36).substring(2,34);
    showDialog: boolean = true;
    user: SpotifyWebApiServer.UserProfileResponse | null = null;

    constructor() {
        if(process.env.REACT_APP_SPOTIFY_CLIENT_ID && process.env.REACT_APP_SPOTIFY_REDIRECT_URI) {
            this.clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
            this.redirectUri = process.env.REACT_APP_SPOTIFY_REDIRECT_URI;

            this.spotifyApi = new SpotifyWebApi({
                clientId: this.clientId,
                redirectUri: this.redirectUri
            });
        }else{
            console.error("No Spotify client id or redirect uri found in .env file");
        }
    }

    getAuthorizeUrl() {
        if(!this.spotifyApi) {
            console.error("Spotify api not initialized")
            return "";
        }
        // @ts-ignore
        return this.spotifyApi.createAuthorizeURL(this.scopes, this.state, this.showDialog, 'token');
    }

    setAccessToken(token: string) {
        if(!this.spotifyApi) {
            console.error("Spotify api not initialized")
            return;
        }
        this.spotifyApi.setAccessToken(token);
    }

    async getMe() {
        if(!this.spotifyApi) {
            console.error("Spotify api not initialized")
            return;
        }
        const data = await this.spotifyApi.getMe();
        if(data.statusCode === 200) {
            this.user = data.body;
        }else{
            console.error("Error getting user", data)
            this.user = null;
        }
    }

    accessTokenSet() {
        if(!this.spotifyApi) {
            console.error("Spotify api not initialized")
            return false;
        }
        return this.spotifyApi.getAccessToken() !== "" && this.spotifyApi.getAccessToken() !== undefined;
    }

    async getArtistsPictureAndPlaylist(artist_id: string) {
        if(!this.spotifyApi) {
            console.error("Spotify api not initialized")
            return {picture: "",  uri: ""};
        }

        const artist = await this.spotifyApi.getArtist(artist_id);

        if(artist.statusCode === 200) {
            return {picture: artist.body.images.length > 0 ? artist.body.images[0].url : "", uri: artist.body.uri};
        }else {
            console.error("Error getting artist", artist)
            return {picture: "",  uri: ""};
        }
    }
    async getTopArtists() {
        if(!this.spotifyApi) {
            console.error("Spotify api not initialized")
            return;
        }
        let done = false;
        let artists: Artist[] = [];
        let params: {limit?: number, offset?: number} = {offset: 0, limit: 50}

        while (!done) {
            let data = await this.spotifyApi.getMyTopArtists({...params});

            for (let artist of data.body.items) {
                if (!artists.find(a => a.id === artist.id)) {
                    artists.push({
                        id: artist.id,
                        name: artist.name,
                        picture: artist.images.length > 0 ? artist.images[0].url : "default.png",
                        uri: artist.uri
                    });
                }
            }
            if (!data.body.next) {
                done = true;
            }else{
                params = {offset: params.offset ? params.offset + 50 : 50, limit: 50}
            }
        }
        return artists;
    }

    async getMySavedSongs(previous_artists?: SavedArtists) {
        if(!this.spotifyApi) {
            console.error("Spotify api not initialized")
            return;
        }
        let done = false;
        let last_checked_song: song| undefined;
        let artists: Artist[] = [];
        let params: {limit?: number, offset?: number} = {offset: 0, limit: 50}

        if(previous_artists !== undefined && previous_artists.user_id !== this.user.id) {
            console.log("User id does not match, resetting previous artists")
            previous_artists = undefined;
        }

        if(previous_artists !== undefined) {
            artists = previous_artists.artists;
        }

        while (!done) {
            let data = await this.spotifyApi.getMySavedTracks({...params});

            // Get first track
            if(!last_checked_song){
                last_checked_song = {
                    id: data.body.items[0].track.id,
                    added_at: data.body.items[0].added_at,
                    name: data.body.items[0].track.name
                }
            }

            for (let track of data.body.items) {

                if(previous_artists){
                    if(track.track.id === previous_artists.last_checked.id) {
                        done = true;
                        break;
                    }
                }

                for(let artist of track.track.artists) {
                    if (!artists.find(a => a.id === artist.id)) {

                        artists.push({
                            id: artist.id,
                            name: artist.name,
                            picture: "",
                            uri: ""
                        });
                    }
                }
            }
            if (!data.body.next) {
                done = true;
            }else{
                params = {offset: params.offset ? params.offset + 50 : 50, limit: 50}
            }
        }

        const topArtists = await this.getTopArtists();

        if(topArtists){
            artists = artists.concat(topArtists.filter(a => !artists.find(b => b.id === a.id)));
        }

        return {"last_checked": last_checked_song, "artists": artists, user_id: this.user.id};
    }

    async logout() {
        if(!this.spotifyApi) {
            console.error("Spotify api not initialized")
            return;
        }
        this.spotifyApi.resetAccessToken();
        this.user = null;
    }
}

export default SpotifyConnection;
