import React, {FunctionComponent} from 'react';
import {RefreshRounded, Delete} from "@material-ui/icons";

interface OwnProps {
    onClick?: () => void;
    onTrash?: () => void;
}

type Props = OwnProps;

const FunctionBar: FunctionComponent<Props> = (props) => {

    return (<div className={"w-full flex justify-end my-6 fixed z-10 bottom-0"}>
        <div className={"self-end justify-around flex p-5 rounded-md"}>
            <div className={"flex justify-center flex-row"}>
                <div className={"rounded-full w-16 h-16 bg-primary flex justify-center mx-2"} onClick={props.onClick}>
                    <RefreshRounded className={"text-white self-center"}/>
                </div>
                <div className={"rounded-full w-16 h-16 bg-primary flex justify-center mx-2"} onClick={props.onTrash}>
                    <Delete className={"text-white self-center"}/>
                </div>
            </div>
        </div>
    </div>);
};

export default FunctionBar;
