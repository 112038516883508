import React, {useMemo} from 'react';
import SpotifyConnection from "./utils/spotify";
import {useLocation, useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
import {Program} from "./types/VierdaagseApi";
import ProgramList from "./components/Program";
import Navbar from "./components/Navbar";
import StartSpotifySearch from "./components/StartSpotifySearch";
import FunctionBar from "./components/FunctionBar";
import {findMatches} from "./utils/matchArtists";
import Lottie from "lottie-react";
import SpotifyAnimation from "./spotify.json";

function App() {

    const [spotify, setSpotify] = React.useState<SpotifyConnection | null>(null);
    const [rerender, setRerender] = React.useState(false);
    const [acts, setActs] = React.useState<Program[]>([]);
    const [Loading, setLoading] = React.useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const cookies = useMemo(() => {return new Cookies()}, []);

    React.useEffect(() => {
        setSpotify(new SpotifyConnection());
    }, []);

    React.useEffect(() => {
        const params_array = location.hash.substring(1).split("&");
        for (const param of params_array) {
            const [key, value] = param.split("=");
            if (key === "access_token") {
                cookies.set('access_token', value, { path: '/', expires: new Date(Date.now() + 3600 * 1000), sameSite: 'strict' });
                spotify?.setAccessToken(value);
                spotify?.getMe().then(() => {
                    navigate("/", {replace: true});
                }).catch((err) => {
                    console.error(err);
                    navigate("/", {replace: true});
                });
            }
        }
    }, [location, spotify, navigate]);

    React.useEffect(() => {
        if(spotify && !spotify.user && !spotify.accessTokenSet()) {
            const access_token = cookies.getAll().access_token;
            if (access_token) {
                spotify.setAccessToken(access_token);
                spotify.getMe().then(() => {
                    setRerender(!rerender)}
                );
            }
        }
    }, [spotify, cookies]);

    React.useEffect(() => {
        if(spotify && !spotify.user && spotify.accessTokenSet()) {
            spotify.getMe();
        }
    }, [spotify]);

  return (
    <div className="App">
        <Navbar spotify={spotify} logout={() => {
            if(spotify) {
                spotify.logout();
                cookies.remove("access_token");
                setRerender(!rerender);
            }
        }} />

        {
            spotify && spotify.user && !Loading &&
            <StartSpotifySearch spotify={spotify} setActs={setActs} acts={acts} setLoading={setLoading}/>
        }

        {
            spotify && spotify.user && acts.length !== 0 &&
            <FunctionBar onClick={() => {
                findMatches(spotify, setActs, setLoading)}
            } onTrash={() => {
                setActs([]);
                localStorage.removeItem('artists');
            }} />
        }

        {
            spotify  && Loading &&
            <div className={"flex flex-col w-screen h-96"}>
                <Lottie animationData={SpotifyAnimation} loop={true}  className={"w-full h-full"}/>
                <h1 className={"text-white text-center font-bold text-2xl"}>Loading...</h1>
            </div>
        }
        {
            acts && !Loading &&
            <div className={"mt-6 pb-52"}>
                <ProgramList programs={acts}/>
            </div>
        }

        {
            spotify && !spotify.user &&
            <div className={"flex justify-center flex-col mt-32"}>
                <h1 className={"text-3xl font-bold text-center text-white"}>Login with Spotify to start searching for your favorite songs!</h1>
                <a href={spotify.getAuthorizeUrl()} className={"bg-white h-16 w-16 rounded-full flex justify-center self-center mt-5"}>
                    <img src={"https://upload.wikimedia.org/wikipedia/commons/1/19/Spotify_logo_without_text.svg"} alt={"Spotify logo"} className={"self-center"}/>
                </a>
            </div>
        }
    </div>
  );
}

export default App;
