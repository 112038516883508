import React, { FunctionComponent } from 'react';
import SpotifyConnection from "../utils/spotify";

interface OwnProps {
    spotify: SpotifyConnection | null;
    logout: () => void;
}

type Props = OwnProps;

const navbar: FunctionComponent<Props> = (props) => {

  return (<>
      <nav className={"w-full top-0 relative"}>
            <div className={"flex flex-col sm:flex-row justify-between items-center bg-secondary p-5"}>
                <a href={"/"} className={"text-xl font-bold mb-5 sm:mb-0"}>Vierdaagsefeesten connect</a>
                <div>
                    {
                        props.spotify && props.spotify.user &&
                        <div className={"flex flex-row justify-center"}>
                            <img className={"rounded-full w-8 h-8 object-cover  self-center"} src={props.spotify.user.images && props.spotify.user.images.length > 0 ? props.spotify.user.images[0].url : "https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg"} alt={"profile from spotify"}/>
                            <p className={"text-xl font-bold mx-3 self-center"}>{props.spotify.user.display_name}</p>
                            <button onClick={props.logout} className={"text-xl font-bold self-center bg-primary rounded-full px-3 py-1 text-white"}>Logout</button>
                        </div>
                    }
                    {
                        props.spotify && !props.spotify.user &&
                        <div className={"bg-primary rounded-full px-3 py-1"}>
                            <a href={props.spotify.getAuthorizeUrl()} className={"text-xl font-bold text-white"}>Login</a>
                        </div>
                    }
                </div>
            </div>
      </nav>
  </>);
};

export default navbar;
