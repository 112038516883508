import {Location} from "../types/VierdaagseApi";

export function truncateString(str: string, num: number) {

    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

export function convertDateStringToDate(dateString: string, addDay?: boolean) {
    const date = new Date(dateString);
    if(addDay) {
        return date.toLocaleDateString('nl-NL', {weekday: 'long', day: 'numeric', month: 'long'});
    }
    return date.toLocaleDateString('nl-NL');
}

export function sanitizeHTMLTags(htmlString: string) {
    htmlString = htmlString.replaceAll('<br />', '\n');
    return htmlString.replace(/<[^>]*>?/gm, '');
}

export function locationIdToLocation(id: number, locations: Location[]){
    for(let location of locations) {
        if(location.id === id) {
            return location;
        }
    }
    return {'id': id};
}
