import React, { FunctionComponent } from 'react';
import {Program} from "../types/VierdaagseApi";
import {convertDateStringToDate, sanitizeHTMLTags, truncateString} from "../utils/helper";



const SingleProgram: FunctionComponent<Program> = (program) => {

  return (<>
    <div className={"flex flex-col bg-secondary rounded-lg m-2 mx-5"}>
        <img className={"h-64 object-cover object-top rounded-t-lg overflow-clip"} src={program.images.length > 0 ? program.images[0].url : "default.png"} alt={"Image for act " + program.title}/>
        <div className={"flex flex-col p-5"}>
            <h2 className={"font-bold text-2xl"}>{program.title}</h2>
            <h3 className={"text-lg"}>{convertDateStringToDate(program.day.date)}</h3>
            <h3 className={"text-md"}>{program.start_time} - {program.end_time}</h3>
            <h3 className={"text-md"}>{('title' in program.location) ? program.location.title : ""}</h3>
            <p className={"mt-3"}>{truncateString(sanitizeHTMLTags(program.description), 200)}</p>
            <a href={program.url} className={"text-md font-bold mt-2"}>Listen to their songs</a>
        </div>
    </div>
  </>);
};

const ProgramList: FunctionComponent<{programs: Program[]}> = ({programs}) => {
    let currentDay = "";

    return (
        <div className={"grid lg:grid-cols-5 gap-4 md:grid-cols-2 grid-cols-1"}>
            {programs.map((program) => {
                if(currentDay !== program.day.date) {
                    currentDay = program.day.date;
                    return (
                        <>
                            <h2 className={"col-span-full text-3xl font-bold text-white text-center py-10 bg-primary"}>{convertDateStringToDate(program.day.date, true)}</h2>
                            <SingleProgram {...program} key={program.id}/>
                        </>);
                }else{
                    return <SingleProgram {...program} key={program.id}/>
                }
            })}
        </div>
    )
}


export default ProgramList;
