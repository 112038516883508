import React, { FunctionComponent } from 'react';
import {PlayArrow} from "@material-ui/icons";
import SpotifyConnection from "../utils/spotify";
import {findMatches} from "../utils/matchArtists";
import {Program} from "../types/VierdaagseApi";

interface OwnProps {
    spotify: SpotifyConnection | null;
    setActs: (acts: Program[]) => void;
    setLoading: (loading: boolean) => void;
    acts: Program[];
}

type Props = OwnProps;


const StartSpotifySearch: FunctionComponent<Props> = (props) => {


  return (
      <div>
          {props.spotify && props.spotify.user && props.acts.length === 0 &&
              <div className={"flex justify-center flex-col mt-32 mx-2"}>
                  <h1 className={"text-3xl font-bold text-center text-white"}>Start searching for your favorite songs!</h1>
                  <div className={"bg-primary h-16 w-16 rounded-full flex justify-center self-center mt-5"} onClick={() => {findMatches(props.spotify, props.setActs, props.setLoading)}}>
                      <PlayArrow className={"self-center"}/>
                  </div>
              </div>
        }
      </div>
  );
};

export default StartSpotifySearch;
